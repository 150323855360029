<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
          <v-select
            v-model="typeID"
            :items="types"
            outlined
            label="Origen datos"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
        <id-selector
          :label="typeID === 'id' ? 'Dviewcontent OLD' : 'Acttiv'"
          :type="typeID === 'id' ? 'dviewcontentOldID' : typeID"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="ID"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-checkbox
          v-model="hh"
          label="Ocultar cabecera"
          @change="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-checkbox
          v-model="player"
          label="Player"
          @change="prepareURL"
          hide-details
          outlined
        />
        <small style="color: #CCCCCC;">NOTA: oculta el selector de semana </small>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-checkbox
          v-model="totem"
          label="Totem"
          @change="prepareURL"
          hide-details
          outlined
        />
        <small style="color: #CCCCCC;">NOTA: oculta el bot�n de a�adir al calendario </small>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
        <v-text-field
          v-model="category"
          label="CategoryID"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="locale"
            :items="languages"
            outlined
            chips
            small-chips
            label="Idioma"
            itemText="NormalName"
            itemValue="ID"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="addDays"
          label="A�adir d�a (0-hoy, 1-ma�ana)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="rows"
          label="Filas"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color1"
          label="Color 1 (cabecera)"
          :onChange="handleChangeColor1"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color2"
          label="Color 2 (selector semana)"
          :onChange="handleChangeColor2"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color3"
          label="Color 3 (t�tulo, hora, d�a)"
          :onChange="handleChangeColor3"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color4"
          label="Color 4 (hora)"
          :onChange="handleChangeColor4"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color5"
          label="Color 5 (fondo)"
          :onChange="handleChangeColor5"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="interval"
          label="Interval"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        v-if="typeID === 'acttiv'"
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-select
          v-model="images"
          :items="folders"
          outlined
          label="Im�genes Acttiv"
          itemText="name"
          itemValue="id"
          hide-details
          @change="prepareURL"
        />
      </v-col>
      <!--v-col
        v-if="typeID === 'acttiv'"
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="images"
          label="Im�genes Acttiv"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col-->
      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    ColorPickerItem,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    typeID: 'id',
    url: null,
    zoom: 100,
    id: null, //'61ba5270-9bfe-11e9-a660-51217a9d4465',
    locale: 'es',
    color1: null,
    color2: null,
    color3: null,
    color4: null,
    color5: null,
    addDays: 0,
    hh: true,
    player: true,
    totem: true,
    category: null,
    images: 'acttiv',
    rows: null,
    interval: '30000',
    parentWidth: null,
  }),
  computed: {
    types () {
      return [
        {
          id: 'acttiv',
          name: 'Acttiv'
        },
        {
          id: 'id',
          name: 'Dviewcontent OLD'
        },
      ]
    },
    folders () {
      return [
        { id: 'genericas', name: 'genericas' }, 
        { id: 'barcelonew', name: 'barcelonew' }, 
        { id: 'acttiv', name: 'acttiv' }
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  watch: {
    typeID () {
      this.id = null
      this.prepareURL()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    handleChangeBGColor (v) {
      this.bgColor = v
      this.prepareURL()
    },
    handleChangeColor1 (v) {
      this.color1 = v
      this.prepareURL()
    },
    handleChangeColor2 (v) {
      this.color2 = v
      this.prepareURL()
    },
    handleChangeColor3 (v) {
      this.color3 = v
      this.prepareURL()
    },
    handleChangeColor4 (v) {
      this.color4 = v
      this.prepareURL()
    },
    handleChangeColor5 (v) {
      this.color5 = v
      this.prepareURL()
    },
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      //this.url = `https://widgets.dviewcontent.com/activity/?locale=EN&acttiv=2535&addDays=0&c1=3C3C3B&c2=F9C031&c3=3C3C3B&c4=cc2526&c5=FFFFFF&hh=true&totem=true&player=false&images=barcelonew&f=7&interval=30000`
      this.url = `https://widgets.dviewcontent.com/activity/?`
        .concat(`&${this.typeID}=${this.id}`)
        .concat(`&locale=${this.locale.toUpperCase()}`)
        .concat(`&addDays=${this.addDays ? this.addDays : 0}`)
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&zoom=${this.zoom}`: '')
        .concat(this.color1 && this.color1.length > 0 ? `&c1=${this.color1.replace('#','')}`: '')
        .concat(this.color2 && this.color2.length > 0 ? `&c2=${this.color2.replace('#','')}`: '')
        .concat(this.color3 && this.color3.length > 0 ? `&c3=${this.color3.replace('#','')}`: '')
        .concat(this.color4 && this.color4.length > 0 ? `&c4=${this.color4.replace('#','')}`: '')
        .concat(this.color5 && this.color5.length > 0 ? `&c5=${this.color5.replace('#','')}`: '')
        .concat(this.interval && this.interval.length > 0 ? `&interval=${this.iterval}`: '')
        .concat(this.typeID === 'acttiv' && this.images && this.images.length > 0 ? `&images=${this.images}`: '')
        .concat(this.rows && this.rows.length > 0 ? `&f=${this.rows}`: '')
        .concat(this.hh ? `&hh=true`: '&hh=false')
        .concat(this.player ? `&player=true`: '&player=false')
        .concat(this.totem ? `&totem=true`: '&totem=false')
        //.concat(this.category && this.category.length > 0 ? `&category=${this.category}`: '')
    },
  },
}
</script>

