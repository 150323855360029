<template>
  <div v-if="url" style="display: flex;justify-content: center;">
    <div
      style="user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        padding-right: 10px;
        font-style: italic;
        color: gray;
      "

    >
      {{url}}
    </div> 
    <v-btn color="primary" @click="handleCopy" small><v-icon small>mdi-content-copy</v-icon></v-btn>
  </div>
  <div v-else style="display: flex;justify-content: center;color:red;">
    <b>[ ID not found ]</b> <span style="font-style: italic;padding-left:10px;font-size:0.9rem"> debe seleccionar un identificador</span>
  </div>
</template>
<script>
import apiService from '@/services/api'
import utilsService from '@/services/utils'
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleCopy () {
      if (!this.url) return
      apiService.put ('system', `v1/system/users/${utilsService.getUser()}/prepare-url`, { url: this.url })
        .then(() => {                      
          this.onGetData()
          this.onCloseDialog()
        })
      this.copyTextToClipboard(this.url)
      alert('Copiado!!!')
    },
    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement("textarea")
      textArea.value = text;
      
      // Avoid scrolling to bottom
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!')
      }, function(err) {
        console.error('Async: Could not copy text: ', err)
      });
    }
  },
}
</script>

