var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"calc(100vh)"},style:({ width: _vm.parentWidth ? (_vm.parentWidth + "px"): null })},[_c('iframe',{staticStyle:{"border-radius":"5px"},style:({
        width: ((_vm.size.w) + "px"),
        height: ((_vm.size.h) + "px"),
        'background-color': 'transparent',
        '-ms-zoom': _vm.scale,
        '-moz-transform': ("scale(" + _vm.scale + ")"),
        '-moz-transform-origin': '0 0',
        '-o-transform': ("scale(" + _vm.scale + ")"),
        '-o-transform-origin': '0 0',
        '-webkit-transform': ("scale(" + _vm.scale + ")"),
        '-webkit-transform-origin': '0 0',
    }),attrs:{"id":"previewDtouch","src":_vm.url}}),_c('small',{staticStyle:{"opacity":"0"}},[_vm._v(_vm._s(parseInt(_vm.parentWidth))+" x "+_vm._s(parseInt(_vm.height)))])])}
var staticRenderFns = []

export { render, staticRenderFns }