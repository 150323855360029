<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      sm="12"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      />
        <v-autocomplete
          v-if="items"
          v-model="selectedID"
          :items="items"
          outlined
          chips
          small-chips
          :label="label"
          itemText="name"
          itemValue="id"
          hide-details
          @change="handleChange"
        />
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import apiService from '@/services/api'
export default {
  props: {
    label: {
      type: String,
      default: 'ID',
    },
    value: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    loading: false,
    selectedID: null,
    items: null,
  }),
  watch: {
    value () {
      this.prepareValue()
    },
    type () {
      this.items = null
      this.handleGetData()
    },
  },
  mounted () {
    this.prepareValue()
    this.handleGetData()
  },
  methods: {
    prepareValue () {
      if (this.value !== this.selectedID) this.selectedID = this.value
    },
    handleGetData () {
      this.items = null
      if (!this.type) return
      if (['acttiv', 'hotel'].indexOf(this.type) >= 0) this.handleGetActtiv()
      if (['bookingID'].indexOf(this.type) >= 0) this.handleGetBooking()
      if (['cmdviewcontent'].indexOf(this.type) >= 0) this.handleGetCMDviewcontentNew()
      if (['showID', 'dviewcontentOldID'].indexOf(this.type) >= 0) this.handleGetDviewcontentOld(true)
      if (['dviewcontentOldName'].indexOf(this.type) >= 0) this.handleGetDviewcontentOld(false)
      if (['dtouchSubdomain'].indexOf(this.type) >= 0) this.handleGetDtouch(true)
    },
    handleGetDtouch (isSubdomain) {
      this.loading = true
      apiService.getAllWithoutLimit('dtouch', 'v1/system/workspaces')
        .then(response => {
          this.items = [{
            id: null,
            name: '',
          }, ...response.data.map(item => {
            return ({
              id: isSubdomain ? item.Domain : item.ID,
              name: item.Name || item.ID
            })
          })]
          this.loading = false
      })
      .catch (error => {
        console.log(error)
        this.loading = false
      })
    },
    handleGetBooking () {
      this.loading = true
      apiService.getAllWithoutLimit('booking', 'v1/system/workspaces/')
        .then(response => {
          this.items = [{
            id: null,
            name: '',
          }, ...response.data.map(item => {
            return ({
              id: item.ID,
              name: item.Name || item.ID
            })
          })]
          this.loading = false
      })
      .catch (error => {
        console.log(error)
        this.loading = false
      })
    },
    handleGetCMDviewcontentNew () {
      this.loading = true
      apiService.getAllWithoutLimit('booking', 'v1/system/cm-dviewcontent')
        .then(response => {
          this.items = [{
            id: null,
            name: '',
          }, ...response.map(item => {
            return ({
              id: item.ID,
              name: item.Name || item.ID
            })
          })]
          this.loading = false
      })
      .catch (error => {
        console.log(error)
        this.loading = false
      })
    },
    handleGetDviewcontentOld (isID) {
      this.loading = true
      apiService.getAllWithoutLimit('booking', 'v1/system/dviewcontent')
        .then(response => {
          this.items = [{
            id: null,
            name: '',
          }, ...response.map(item => {
            return ({
              id: isID ? item.ID : item.Name,
              name: item.Name
            })
          })]
          this.loading = false
      })
      .catch (error => {
        console.log(error)
        this.loading = false
      })
    },
    handleGetActtiv () {
      this.loading = true
      axios.get('https://acttiv-api.dtouchwebapp.com/v1/public/hotels')
        .then(response => {
          this.items = [{
            id: null,
            name: '',
          },...response.data.map(item => {
            return {
              id: item.ID.toString(),
              name: item.Name || item.ID
            }
          })]
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log (error)
        })
    },
    handleChange () {
      if (this.value !== this.selectedID) this.onChange(this.selectedID)
    },
  }

}
</script>

