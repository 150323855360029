<template>
  <div
    style="border: 1px solid #CCC;min-height:40px;"
    :style="{
      'background-color': color,
      'border-radius': borderRadius}"
    @click="show = !show"
  >
    <center
      v-if="label"
      style="padding: 10px 0;"
      :style="{ color: oppositeColor }"
    >
      <b>{{label}}</b>
    </center>
    <center 
      v-else
      style="padding: 10px 0;"
    >
      <v-icon :style="{ color: oppositeColor }">{{ icon }}</v-icon>
    </center>
    <v-dialog v-model="show" persistent max-width="330px" >
      <v-card style="padding: 10px;">
      <v-color-picker
        v-model="selectedColor"
        class="ma-2"
        :swatches="swatches"
        show-swatches
        mode="hexa"
      />
      <center
        style="padding-bottom: 10px;"
      >
        <v-btn
          @click="handleClear"
          color="red"
          dark
        >
          <v-icon>mdi-water-off</v-icon>
        </v-btn>
        <v-btn
          @click="handleCancel"
          style="margin-left: 10px;"
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
        <v-btn
          @click="handleSave"
          color="primary"
          dark
          style="margin-left: 10px;"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </center>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import tinycolor from 'tinycolor2'
  export default {
    name: 'ColorPickerItem',
    props: {
      label: {
        type: String,
        default: null,
      },
      iconBGAndText: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: 'mdi-format-color-fill',
      },
      value: {
        type: String,
        default: null,
      },
      onChange: {
        type: Function,
        default: null,
      },
      borderRadius: {
        type: String,
        default: '0 0 0 0',
      },
    },
    data: () => ({
      show: false,
      color: '#FFFFFF',
      oppositeColor: '#000000',
      selectedColor: '#FFFFFF',
      resultColor: null,

      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#FFFFFF', '#0000AA', '#000055'],
      ],
    }),
    watch: {
      value () {
        this.prepareColor(this.value)
      },
    },
    mounted () {
      this.prepareColor(this.value)
    },
    methods: {
      prepareClear () {
        this.resultColor = null
        this.selectedColor = '#FFFFFF'
        this.color = '#FFFFFF'
        this.opositeColor = '#000000'
      },
      prepareColor (v) {
        if (v) {
          this.resultColor = v
          this.color = v
          this.oppositeColor = this.getOppositeColor(v)
          this.selectedColor = v
        } else this.prepareClear()
      },
      getOppositeColor(v) {
        if (!v) return '#000000'
        return tinycolor(v).isLight()  ? '#000000' : '#FFFFFF'
      },
      handleCancel () {
        this.show = false
      },
      handleSave () {
        const aux = this.selectedColor && this.selectedColor.hex ? this.selectedColor.hex : this.selectedColor
        this.resultColor = aux
        this.color = aux
        this.oppositeColor = this.getOppositeColor(aux)
        this.show = false
        this.onChange(this.resultColor)
      },
      handleClear () {
        this.prepareClear()
        this.onChange(this.resultColor)
        this.show = false
      }
    },
  }
</script>

